.pushable{
    border: none !important;
    border-radius: 0 !important;
}

:root {
    --lh: 1.4rem;
}

.truncate-overflow {
    --max-lines: 3;
    position: relative;
    max-height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
  }

.truncate-overflow::before {
    position: absolute;
    content: "...";
    inset-block-end: 0;
    inset-inline-end: 0;
    right: 20;
  }
.truncate-overflow::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    background: white;
}

.bigger-checkbox{
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
    padding: 10px;
}

.paddingSideBar{
    padding-left:175px !important; 
    display:block;
}
.blockButton{
    display:block !important;
}

.ReactPasswordStrength-strength-desc{
    margin-right: 20px;
    float: right;
    position: right;
    width: 30% !important;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {
    .ui.two.cards>.card {
        width: calc(100% - 2em);
        display:block;
    }    
    .rdrMonth , .rdrMonthAndYearWrapper{
        padding-right:200px !important;
        min-width: 0vh !important;
    }
    .rdrMonthAndYearWrapper > button {
        display:none;
    }
    .ui.thin.left.sidebar{
        width:100px !important;
    }
    .paddingSideBar{
        padding-left:125px !important; 
        display:block;
    }
    .hiddenOnMobile{
        display: none !important;
    }
    .blockButton{
        width: 100%;
    }
}