body{
    background-color: rgb(234,234,234) !important;
}
html, body {
    overflow-x:hidden 
  } 
.ui.inverted.top.fixed.menu{
    background-image: linear-gradient(
    135deg,
    rgb(24,42,115) 0%,
    rgb(33,138,174) 69%,
    rgb(32,167,172) 89%)
    !important;
}
